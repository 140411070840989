import { DocumentNode } from 'graphql';
import React from 'react';
import {
  IFdrPopupControl,
  TFdrTravelDestinationAutocompleteConfig,
} from '@hotelplan/fdr.regular.fusion.fdr-autocomplete-combobox';
import {
  IFdrDestinationAutocompleteVariables,
  TFdrFlatDestination,
} from '@hotelplan/fdr.regular.fusion.fdr-travel-destination';
import { FdrPagination } from '@hotelplan/supergraph-api';
import { FdrAutocompleteItemFragment } from 'fdr/schemas/query/search/autocomplete/fdr-text-search-autocomplete.generated';

export enum ESearchType {
  GEO = 'geo',
  SRL = 'srl',
  PDP = 'pdp',
  HOME = 'home',
  THEME = 'theme',
  THEME_OVERVIEW = 'theme_overview',
  FLIGHT = 'flight',
  WISHLIST = 'wishlist',
  ORL = 'orl',
}

export interface IFdrGenericTravelDestinationProps<TData, TVariables> {
  queryDocument: DocumentNode;
  dataMapper: (data: TData | undefined) => IFdrDestinationData | undefined;
  variablesMapper: (
    commonVars: IFdrDestinationAutocompleteVariables
  ) => TVariables;
  suggestionMapper?(
    destination: FdrAutocompleteItemFragment
  ): TFdrFlatDestination;
  onSelect?(
    item: TFdrFlatDestination,
    query: string,
    popupControl: IFdrPopupControl
  ): void;
  disabled?: boolean;
  limit?: number;
  config?: TFdrTravelDestinationAutocompleteConfig;
  customLabel?: React.ReactNode;
  caption?: React.ReactNode;
  countDataSource?: React.ReactNode;
  topDestinationsShown?: boolean;
  renderSuggestion?(item: TFdrFlatDestination, query: string): React.ReactNode;
}

export type TFdrGenericTravelDestinationWithCountProps<TData, TVariables> =
  Omit<
    IFdrGenericTravelDestinationProps<TData, TVariables>,
    'suggestionMapper'
  >;

export interface IFdrDestinationData {
  page: FdrPagination | undefined;
  destinations: FdrAutocompleteItemFragment[];
}
