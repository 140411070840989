import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrSearchControlsResponseFragmentDoc } from 'fdr/schemas/fragment/search-controls/fdr-search-controls-response.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrPdpSearchControlsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  searchQuery?: Types.InputMaybe<Types.Scalars['String']>;
  travelType?: Types.InputMaybe<Types.FdrProductTravelType>;
}>;

export type FdrPdpSearchControlsQuery = {
  __typename?: 'Query';
  fdrProduct?:
    | {
        __typename?: 'FdrCruise';
        id: string;
        searchControls: {
          __typename?: 'FdrSearchControlsResponse';
          controls: {
            __typename?: 'FdrSearchControls';
            rooms: number;
            travelType: Types.FdrProductTravelType;
            sorting: {
              __typename?: 'FdrSortingControls';
              groupSorting: Types.FdrGroupSorting;
              offerSorting: Types.FdrOfferSorting;
              productSorting: Types.FdrProductSorting;
            };
            travellers: {
              __typename?: 'FdrSearchControlsTravellers';
              adults: number;
              childrenDobs?: Array<any> | null;
              roomDistribution?: Array<{
                __typename?: 'FdrSearchControlsTravellersDistribution';
                childrenDobs?: Array<any> | null;
                adults: number;
              }> | null;
            };
            travelPeriod: {
              __typename?: 'FdrSearchControlsTravelPeriod';
              defaultPeriod: Types.FdrTravelPeriodType;
              exactDepartureDate: any;
              exactReturnDate: any;
              flexibleDepartureDate: any;
              flexibleReturnDate: any;
              flexibleDurations: Array<number>;
            };
            filters?: Array<
              | {
                  __typename: 'FdrSearchControlsMultiSelectFilter';
                  visible: boolean;
                  id: string;
                  name?: string | null;
                  options: Array<
                    | {
                        __typename: 'FdrSearchControlsAccommodationSizeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsAirlineOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        airline?: {
                          __typename?: 'FdrAirline';
                          name: string;
                          iata: string;
                        } | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsDepartureAirportOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                        airport?: {
                          __typename?: 'FdrAirport';
                          name: string;
                          iata: string;
                          codes?: Array<string> | null;
                          isGroup: boolean;
                        } | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsDepartureFlightDepartureTimeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        from?: any | null;
                        to?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsFlightDurationOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        maxDuration?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsFlightProviderOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                      }
                    | {
                        __typename: 'FdrSearchControlsHpRatingOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        rating?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMaxPriceOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        maxPrice?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMaxStopsOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                        value?: number | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMealTypeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMinPriceOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        minPrice?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsProductCodeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                      }
                    | {
                        __typename: 'FdrSearchControlsProductFeatureOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsProductProviderOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                      }
                    | {
                        __typename: 'FdrSearchControlsReturnFlightDepartureTimeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        to?: any | null;
                        from?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsRoomTypeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsTaRatingOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        rating?: number | null;
                        caption?: string | null;
                      }
                  >;
                }
              | {
                  __typename: 'FdrSearchControlsOneOptionFilter';
                  visible: boolean;
                  id: string;
                  name?: string | null;
                  option:
                    | {
                        __typename: 'FdrSearchControlsAccommodationSizeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsAirlineOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        airline?: {
                          __typename?: 'FdrAirline';
                          name: string;
                          iata: string;
                        } | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsDepartureAirportOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                        airport?: {
                          __typename?: 'FdrAirport';
                          name: string;
                          iata: string;
                          codes?: Array<string> | null;
                          isGroup: boolean;
                        } | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsDepartureFlightDepartureTimeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        from?: any | null;
                        to?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsFlightDurationOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        maxDuration?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsFlightProviderOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                      }
                    | {
                        __typename: 'FdrSearchControlsHpRatingOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        rating?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMaxPriceOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        maxPrice?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMaxStopsOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                        value?: number | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMealTypeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMinPriceOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        minPrice?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsProductCodeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                      }
                    | {
                        __typename: 'FdrSearchControlsProductFeatureOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsProductProviderOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                      }
                    | {
                        __typename: 'FdrSearchControlsReturnFlightDepartureTimeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        to?: any | null;
                        from?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsRoomTypeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsTaRatingOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        rating?: number | null;
                        caption?: string | null;
                      };
                }
              | {
                  __typename: 'FdrSearchControlsSingleSelectFilter';
                  visible: boolean;
                  id: string;
                  name?: string | null;
                  options: Array<
                    | {
                        __typename: 'FdrSearchControlsAccommodationSizeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsAirlineOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        airline?: {
                          __typename?: 'FdrAirline';
                          name: string;
                          iata: string;
                        } | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsDepartureAirportOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                        airport?: {
                          __typename?: 'FdrAirport';
                          name: string;
                          iata: string;
                          codes?: Array<string> | null;
                          isGroup: boolean;
                        } | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsDepartureFlightDepartureTimeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        from?: any | null;
                        to?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsFlightDurationOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        maxDuration?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsFlightProviderOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                      }
                    | {
                        __typename: 'FdrSearchControlsHpRatingOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        rating?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMaxPriceOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        maxPrice?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMaxStopsOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                        value?: number | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMealTypeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMinPriceOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        minPrice?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsProductCodeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                      }
                    | {
                        __typename: 'FdrSearchControlsProductFeatureOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsProductProviderOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                      }
                    | {
                        __typename: 'FdrSearchControlsReturnFlightDepartureTimeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        to?: any | null;
                        from?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsRoomTypeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsTaRatingOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        rating?: number | null;
                        caption?: string | null;
                      }
                  >;
                }
            > | null;
            travelDestinations?: {
              __typename?: 'FdrSearchControlsTravelDestinations';
              selectedSubGeo?: Array<string> | null;
              destinations?: Array<
                | {
                    __typename?: 'FdrSearchControlsAirportDestination';
                    airport: {
                      __typename?: 'FdrAirport';
                      iata: string;
                      name: string;
                      codes?: Array<string> | null;
                      isGroup: boolean;
                      id: string;
                    };
                  }
                | {
                    __typename?: 'FdrSearchControlsGeoDestination';
                    geo:
                      | {
                          __typename?: 'FdrDestination';
                          name: string;
                          id: string;
                          region?: {
                            __typename?: 'FdrRegion';
                            name: string;
                            id: string;
                            country: {
                              __typename?: 'FdrCountry';
                              name: string;
                              id: string;
                              countryGroup?: {
                                __typename?: 'FdrCountryGroup';
                                name: string;
                                id: string;
                                continent: {
                                  __typename?: 'FdrContinent';
                                  name: string;
                                  id: string;
                                };
                              } | null;
                              continent: {
                                __typename?: 'FdrContinent';
                                name: string;
                                id: string;
                              };
                            };
                          } | null;
                          country: {
                            __typename?: 'FdrCountry';
                            name: string;
                            id: string;
                            countryGroup?: {
                              __typename?: 'FdrCountryGroup';
                              name: string;
                              id: string;
                              continent: {
                                __typename?: 'FdrContinent';
                                name: string;
                                id: string;
                              };
                            } | null;
                            continent: {
                              __typename?: 'FdrContinent';
                              name: string;
                              id: string;
                            };
                          };
                        }
                      | {
                          __typename?: 'FdrCountry';
                          name: string;
                          id: string;
                          countryGroup?: {
                            __typename?: 'FdrCountryGroup';
                            name: string;
                            id: string;
                            continent: {
                              __typename?: 'FdrContinent';
                              name: string;
                              id: string;
                            };
                          } | null;
                          continent: {
                            __typename?: 'FdrContinent';
                            name: string;
                            id: string;
                          };
                        }
                      | {
                          __typename?: 'FdrContinent';
                          name: string;
                          id: string;
                        }
                      | {
                          __typename?: 'FdrCountryGroup';
                          name: string;
                          id: string;
                          continent: {
                            __typename?: 'FdrContinent';
                            name: string;
                            id: string;
                          };
                        }
                      | {
                          __typename?: 'FdrRegion';
                          name: string;
                          id: string;
                          country: {
                            __typename?: 'FdrCountry';
                            name: string;
                            id: string;
                            countryGroup?: {
                              __typename?: 'FdrCountryGroup';
                              name: string;
                              id: string;
                              continent: {
                                __typename?: 'FdrContinent';
                                name: string;
                                id: string;
                              };
                            } | null;
                            continent: {
                              __typename?: 'FdrContinent';
                              name: string;
                              id: string;
                            };
                          };
                        }
                      | {
                          __typename?: 'FdrResort';
                          name: string;
                          id: string;
                          destination: {
                            __typename?: 'FdrDestination';
                            name: string;
                            id: string;
                            region?: {
                              __typename?: 'FdrRegion';
                              name: string;
                              id: string;
                              country: {
                                __typename?: 'FdrCountry';
                                name: string;
                                id: string;
                                countryGroup?: {
                                  __typename?: 'FdrCountryGroup';
                                  name: string;
                                  id: string;
                                  continent: {
                                    __typename?: 'FdrContinent';
                                    name: string;
                                    id: string;
                                  };
                                } | null;
                                continent: {
                                  __typename?: 'FdrContinent';
                                  name: string;
                                  id: string;
                                };
                              };
                            } | null;
                            country: {
                              __typename?: 'FdrCountry';
                              name: string;
                              id: string;
                              countryGroup?: {
                                __typename?: 'FdrCountryGroup';
                                name: string;
                                id: string;
                                continent: {
                                  __typename?: 'FdrContinent';
                                  name: string;
                                  id: string;
                                };
                              } | null;
                              continent: {
                                __typename?: 'FdrContinent';
                                name: string;
                                id: string;
                              };
                            };
                          };
                        };
                  }
                | {
                    __typename?: 'FdrSearchControlsProductDestination';
                    product:
                      | { __typename?: 'FdrCruise'; name: string; id: string }
                      | {
                          __typename?: 'FdrHotel';
                          name: string;
                          mythosCode?: string | null;
                          id: string;
                          resort: {
                            __typename?: 'FdrResort';
                            name: string;
                            id: string;
                            destination: {
                              __typename?: 'FdrDestination';
                              name: string;
                              id: string;
                              region?: {
                                __typename?: 'FdrRegion';
                                name: string;
                                id: string;
                                country: {
                                  __typename?: 'FdrCountry';
                                  name: string;
                                  id: string;
                                  countryGroup?: {
                                    __typename?: 'FdrCountryGroup';
                                    name: string;
                                    id: string;
                                    continent: {
                                      __typename?: 'FdrContinent';
                                      name: string;
                                      id: string;
                                    };
                                  } | null;
                                  continent: {
                                    __typename?: 'FdrContinent';
                                    name: string;
                                    id: string;
                                  };
                                };
                              } | null;
                              country: {
                                __typename?: 'FdrCountry';
                                name: string;
                                id: string;
                                countryGroup?: {
                                  __typename?: 'FdrCountryGroup';
                                  name: string;
                                  id: string;
                                  continent: {
                                    __typename?: 'FdrContinent';
                                    name: string;
                                    id: string;
                                  };
                                } | null;
                                continent: {
                                  __typename?: 'FdrContinent';
                                  name: string;
                                  id: string;
                                };
                              };
                            };
                          };
                        }
                      | {
                          __typename?: 'FdrRoundTrip';
                          name: string;
                          id: string;
                        }
                      | {
                          __typename?: 'FdrAdventureTravel';
                          name: string;
                          id: string;
                        };
                  }
                | {
                    __typename?: 'FdrSearchControlsThemeDestination';
                    theme: {
                      __typename?: 'FdrTheme';
                      name: string;
                      id: string;
                    };
                  }
              > | null;
              radiusSearch?: {
                __typename?: 'FdrSearchControlsRadiusSearch';
                label: string;
                radius: number;
                center: {
                  __typename?: 'FdrCoordinates';
                  latitude: number;
                  longitude: number;
                };
              } | null;
            } | null;
          };
        };
      }
    | {
        __typename?: 'FdrHotel';
        id: string;
        searchControls: {
          __typename?: 'FdrSearchControlsResponse';
          controls: {
            __typename?: 'FdrSearchControls';
            rooms: number;
            travelType: Types.FdrProductTravelType;
            sorting: {
              __typename?: 'FdrSortingControls';
              groupSorting: Types.FdrGroupSorting;
              offerSorting: Types.FdrOfferSorting;
              productSorting: Types.FdrProductSorting;
            };
            travellers: {
              __typename?: 'FdrSearchControlsTravellers';
              adults: number;
              childrenDobs?: Array<any> | null;
              roomDistribution?: Array<{
                __typename?: 'FdrSearchControlsTravellersDistribution';
                childrenDobs?: Array<any> | null;
                adults: number;
              }> | null;
            };
            travelPeriod: {
              __typename?: 'FdrSearchControlsTravelPeriod';
              defaultPeriod: Types.FdrTravelPeriodType;
              exactDepartureDate: any;
              exactReturnDate: any;
              flexibleDepartureDate: any;
              flexibleReturnDate: any;
              flexibleDurations: Array<number>;
            };
            filters?: Array<
              | {
                  __typename: 'FdrSearchControlsMultiSelectFilter';
                  visible: boolean;
                  id: string;
                  name?: string | null;
                  options: Array<
                    | {
                        __typename: 'FdrSearchControlsAccommodationSizeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsAirlineOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        airline?: {
                          __typename?: 'FdrAirline';
                          name: string;
                          iata: string;
                        } | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsDepartureAirportOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                        airport?: {
                          __typename?: 'FdrAirport';
                          name: string;
                          iata: string;
                          codes?: Array<string> | null;
                          isGroup: boolean;
                        } | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsDepartureFlightDepartureTimeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        from?: any | null;
                        to?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsFlightDurationOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        maxDuration?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsFlightProviderOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                      }
                    | {
                        __typename: 'FdrSearchControlsHpRatingOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        rating?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMaxPriceOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        maxPrice?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMaxStopsOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                        value?: number | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMealTypeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMinPriceOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        minPrice?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsProductCodeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                      }
                    | {
                        __typename: 'FdrSearchControlsProductFeatureOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsProductProviderOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                      }
                    | {
                        __typename: 'FdrSearchControlsReturnFlightDepartureTimeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        to?: any | null;
                        from?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsRoomTypeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsTaRatingOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        rating?: number | null;
                        caption?: string | null;
                      }
                  >;
                }
              | {
                  __typename: 'FdrSearchControlsOneOptionFilter';
                  visible: boolean;
                  id: string;
                  name?: string | null;
                  option:
                    | {
                        __typename: 'FdrSearchControlsAccommodationSizeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsAirlineOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        airline?: {
                          __typename?: 'FdrAirline';
                          name: string;
                          iata: string;
                        } | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsDepartureAirportOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                        airport?: {
                          __typename?: 'FdrAirport';
                          name: string;
                          iata: string;
                          codes?: Array<string> | null;
                          isGroup: boolean;
                        } | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsDepartureFlightDepartureTimeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        from?: any | null;
                        to?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsFlightDurationOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        maxDuration?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsFlightProviderOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                      }
                    | {
                        __typename: 'FdrSearchControlsHpRatingOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        rating?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMaxPriceOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        maxPrice?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMaxStopsOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                        value?: number | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMealTypeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMinPriceOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        minPrice?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsProductCodeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                      }
                    | {
                        __typename: 'FdrSearchControlsProductFeatureOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsProductProviderOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                      }
                    | {
                        __typename: 'FdrSearchControlsReturnFlightDepartureTimeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        to?: any | null;
                        from?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsRoomTypeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsTaRatingOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        rating?: number | null;
                        caption?: string | null;
                      };
                }
              | {
                  __typename: 'FdrSearchControlsSingleSelectFilter';
                  visible: boolean;
                  id: string;
                  name?: string | null;
                  options: Array<
                    | {
                        __typename: 'FdrSearchControlsAccommodationSizeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsAirlineOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        airline?: {
                          __typename?: 'FdrAirline';
                          name: string;
                          iata: string;
                        } | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsDepartureAirportOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                        airport?: {
                          __typename?: 'FdrAirport';
                          name: string;
                          iata: string;
                          codes?: Array<string> | null;
                          isGroup: boolean;
                        } | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsDepartureFlightDepartureTimeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        from?: any | null;
                        to?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsFlightDurationOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        maxDuration?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsFlightProviderOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                      }
                    | {
                        __typename: 'FdrSearchControlsHpRatingOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        rating?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMaxPriceOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        maxPrice?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMaxStopsOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                        value?: number | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMealTypeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMinPriceOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        minPrice?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsProductCodeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                      }
                    | {
                        __typename: 'FdrSearchControlsProductFeatureOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsProductProviderOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                      }
                    | {
                        __typename: 'FdrSearchControlsReturnFlightDepartureTimeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        to?: any | null;
                        from?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsRoomTypeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsTaRatingOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        rating?: number | null;
                        caption?: string | null;
                      }
                  >;
                }
            > | null;
            travelDestinations?: {
              __typename?: 'FdrSearchControlsTravelDestinations';
              selectedSubGeo?: Array<string> | null;
              destinations?: Array<
                | {
                    __typename?: 'FdrSearchControlsAirportDestination';
                    airport: {
                      __typename?: 'FdrAirport';
                      iata: string;
                      name: string;
                      codes?: Array<string> | null;
                      isGroup: boolean;
                      id: string;
                    };
                  }
                | {
                    __typename?: 'FdrSearchControlsGeoDestination';
                    geo:
                      | {
                          __typename?: 'FdrDestination';
                          name: string;
                          id: string;
                          region?: {
                            __typename?: 'FdrRegion';
                            name: string;
                            id: string;
                            country: {
                              __typename?: 'FdrCountry';
                              name: string;
                              id: string;
                              countryGroup?: {
                                __typename?: 'FdrCountryGroup';
                                name: string;
                                id: string;
                                continent: {
                                  __typename?: 'FdrContinent';
                                  name: string;
                                  id: string;
                                };
                              } | null;
                              continent: {
                                __typename?: 'FdrContinent';
                                name: string;
                                id: string;
                              };
                            };
                          } | null;
                          country: {
                            __typename?: 'FdrCountry';
                            name: string;
                            id: string;
                            countryGroup?: {
                              __typename?: 'FdrCountryGroup';
                              name: string;
                              id: string;
                              continent: {
                                __typename?: 'FdrContinent';
                                name: string;
                                id: string;
                              };
                            } | null;
                            continent: {
                              __typename?: 'FdrContinent';
                              name: string;
                              id: string;
                            };
                          };
                        }
                      | {
                          __typename?: 'FdrCountry';
                          name: string;
                          id: string;
                          countryGroup?: {
                            __typename?: 'FdrCountryGroup';
                            name: string;
                            id: string;
                            continent: {
                              __typename?: 'FdrContinent';
                              name: string;
                              id: string;
                            };
                          } | null;
                          continent: {
                            __typename?: 'FdrContinent';
                            name: string;
                            id: string;
                          };
                        }
                      | {
                          __typename?: 'FdrContinent';
                          name: string;
                          id: string;
                        }
                      | {
                          __typename?: 'FdrCountryGroup';
                          name: string;
                          id: string;
                          continent: {
                            __typename?: 'FdrContinent';
                            name: string;
                            id: string;
                          };
                        }
                      | {
                          __typename?: 'FdrRegion';
                          name: string;
                          id: string;
                          country: {
                            __typename?: 'FdrCountry';
                            name: string;
                            id: string;
                            countryGroup?: {
                              __typename?: 'FdrCountryGroup';
                              name: string;
                              id: string;
                              continent: {
                                __typename?: 'FdrContinent';
                                name: string;
                                id: string;
                              };
                            } | null;
                            continent: {
                              __typename?: 'FdrContinent';
                              name: string;
                              id: string;
                            };
                          };
                        }
                      | {
                          __typename?: 'FdrResort';
                          name: string;
                          id: string;
                          destination: {
                            __typename?: 'FdrDestination';
                            name: string;
                            id: string;
                            region?: {
                              __typename?: 'FdrRegion';
                              name: string;
                              id: string;
                              country: {
                                __typename?: 'FdrCountry';
                                name: string;
                                id: string;
                                countryGroup?: {
                                  __typename?: 'FdrCountryGroup';
                                  name: string;
                                  id: string;
                                  continent: {
                                    __typename?: 'FdrContinent';
                                    name: string;
                                    id: string;
                                  };
                                } | null;
                                continent: {
                                  __typename?: 'FdrContinent';
                                  name: string;
                                  id: string;
                                };
                              };
                            } | null;
                            country: {
                              __typename?: 'FdrCountry';
                              name: string;
                              id: string;
                              countryGroup?: {
                                __typename?: 'FdrCountryGroup';
                                name: string;
                                id: string;
                                continent: {
                                  __typename?: 'FdrContinent';
                                  name: string;
                                  id: string;
                                };
                              } | null;
                              continent: {
                                __typename?: 'FdrContinent';
                                name: string;
                                id: string;
                              };
                            };
                          };
                        };
                  }
                | {
                    __typename?: 'FdrSearchControlsProductDestination';
                    product:
                      | { __typename?: 'FdrCruise'; name: string; id: string }
                      | {
                          __typename?: 'FdrHotel';
                          name: string;
                          mythosCode?: string | null;
                          id: string;
                          resort: {
                            __typename?: 'FdrResort';
                            name: string;
                            id: string;
                            destination: {
                              __typename?: 'FdrDestination';
                              name: string;
                              id: string;
                              region?: {
                                __typename?: 'FdrRegion';
                                name: string;
                                id: string;
                                country: {
                                  __typename?: 'FdrCountry';
                                  name: string;
                                  id: string;
                                  countryGroup?: {
                                    __typename?: 'FdrCountryGroup';
                                    name: string;
                                    id: string;
                                    continent: {
                                      __typename?: 'FdrContinent';
                                      name: string;
                                      id: string;
                                    };
                                  } | null;
                                  continent: {
                                    __typename?: 'FdrContinent';
                                    name: string;
                                    id: string;
                                  };
                                };
                              } | null;
                              country: {
                                __typename?: 'FdrCountry';
                                name: string;
                                id: string;
                                countryGroup?: {
                                  __typename?: 'FdrCountryGroup';
                                  name: string;
                                  id: string;
                                  continent: {
                                    __typename?: 'FdrContinent';
                                    name: string;
                                    id: string;
                                  };
                                } | null;
                                continent: {
                                  __typename?: 'FdrContinent';
                                  name: string;
                                  id: string;
                                };
                              };
                            };
                          };
                        }
                      | {
                          __typename?: 'FdrRoundTrip';
                          name: string;
                          id: string;
                        }
                      | {
                          __typename?: 'FdrAdventureTravel';
                          name: string;
                          id: string;
                        };
                  }
                | {
                    __typename?: 'FdrSearchControlsThemeDestination';
                    theme: {
                      __typename?: 'FdrTheme';
                      name: string;
                      id: string;
                    };
                  }
              > | null;
              radiusSearch?: {
                __typename?: 'FdrSearchControlsRadiusSearch';
                label: string;
                radius: number;
                center: {
                  __typename?: 'FdrCoordinates';
                  latitude: number;
                  longitude: number;
                };
              } | null;
            } | null;
          };
        };
      }
    | {
        __typename?: 'FdrRoundTrip';
        id: string;
        searchControls: {
          __typename?: 'FdrSearchControlsResponse';
          controls: {
            __typename?: 'FdrSearchControls';
            rooms: number;
            travelType: Types.FdrProductTravelType;
            sorting: {
              __typename?: 'FdrSortingControls';
              groupSorting: Types.FdrGroupSorting;
              offerSorting: Types.FdrOfferSorting;
              productSorting: Types.FdrProductSorting;
            };
            travellers: {
              __typename?: 'FdrSearchControlsTravellers';
              adults: number;
              childrenDobs?: Array<any> | null;
              roomDistribution?: Array<{
                __typename?: 'FdrSearchControlsTravellersDistribution';
                childrenDobs?: Array<any> | null;
                adults: number;
              }> | null;
            };
            travelPeriod: {
              __typename?: 'FdrSearchControlsTravelPeriod';
              defaultPeriod: Types.FdrTravelPeriodType;
              exactDepartureDate: any;
              exactReturnDate: any;
              flexibleDepartureDate: any;
              flexibleReturnDate: any;
              flexibleDurations: Array<number>;
            };
            filters?: Array<
              | {
                  __typename: 'FdrSearchControlsMultiSelectFilter';
                  visible: boolean;
                  id: string;
                  name?: string | null;
                  options: Array<
                    | {
                        __typename: 'FdrSearchControlsAccommodationSizeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsAirlineOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        airline?: {
                          __typename?: 'FdrAirline';
                          name: string;
                          iata: string;
                        } | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsDepartureAirportOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                        airport?: {
                          __typename?: 'FdrAirport';
                          name: string;
                          iata: string;
                          codes?: Array<string> | null;
                          isGroup: boolean;
                        } | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsDepartureFlightDepartureTimeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        from?: any | null;
                        to?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsFlightDurationOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        maxDuration?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsFlightProviderOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                      }
                    | {
                        __typename: 'FdrSearchControlsHpRatingOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        rating?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMaxPriceOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        maxPrice?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMaxStopsOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                        value?: number | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMealTypeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMinPriceOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        minPrice?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsProductCodeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                      }
                    | {
                        __typename: 'FdrSearchControlsProductFeatureOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsProductProviderOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                      }
                    | {
                        __typename: 'FdrSearchControlsReturnFlightDepartureTimeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        to?: any | null;
                        from?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsRoomTypeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsTaRatingOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        rating?: number | null;
                        caption?: string | null;
                      }
                  >;
                }
              | {
                  __typename: 'FdrSearchControlsOneOptionFilter';
                  visible: boolean;
                  id: string;
                  name?: string | null;
                  option:
                    | {
                        __typename: 'FdrSearchControlsAccommodationSizeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsAirlineOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        airline?: {
                          __typename?: 'FdrAirline';
                          name: string;
                          iata: string;
                        } | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsDepartureAirportOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                        airport?: {
                          __typename?: 'FdrAirport';
                          name: string;
                          iata: string;
                          codes?: Array<string> | null;
                          isGroup: boolean;
                        } | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsDepartureFlightDepartureTimeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        from?: any | null;
                        to?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsFlightDurationOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        maxDuration?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsFlightProviderOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                      }
                    | {
                        __typename: 'FdrSearchControlsHpRatingOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        rating?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMaxPriceOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        maxPrice?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMaxStopsOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                        value?: number | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMealTypeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMinPriceOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        minPrice?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsProductCodeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                      }
                    | {
                        __typename: 'FdrSearchControlsProductFeatureOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsProductProviderOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                      }
                    | {
                        __typename: 'FdrSearchControlsReturnFlightDepartureTimeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        to?: any | null;
                        from?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsRoomTypeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsTaRatingOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        rating?: number | null;
                        caption?: string | null;
                      };
                }
              | {
                  __typename: 'FdrSearchControlsSingleSelectFilter';
                  visible: boolean;
                  id: string;
                  name?: string | null;
                  options: Array<
                    | {
                        __typename: 'FdrSearchControlsAccommodationSizeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsAirlineOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        airline?: {
                          __typename?: 'FdrAirline';
                          name: string;
                          iata: string;
                        } | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsDepartureAirportOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                        airport?: {
                          __typename?: 'FdrAirport';
                          name: string;
                          iata: string;
                          codes?: Array<string> | null;
                          isGroup: boolean;
                        } | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsDepartureFlightDepartureTimeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        from?: any | null;
                        to?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsFlightDurationOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        maxDuration?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsFlightProviderOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                      }
                    | {
                        __typename: 'FdrSearchControlsHpRatingOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        rating?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMaxPriceOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        maxPrice?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMaxStopsOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                        value?: number | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMealTypeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMinPriceOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        minPrice?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsProductCodeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                      }
                    | {
                        __typename: 'FdrSearchControlsProductFeatureOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsProductProviderOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                      }
                    | {
                        __typename: 'FdrSearchControlsReturnFlightDepartureTimeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        to?: any | null;
                        from?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsRoomTypeOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsTaRatingOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        rating?: number | null;
                        caption?: string | null;
                      }
                  >;
                }
            > | null;
            travelDestinations?: {
              __typename?: 'FdrSearchControlsTravelDestinations';
              selectedSubGeo?: Array<string> | null;
              destinations?: Array<
                | {
                    __typename?: 'FdrSearchControlsAirportDestination';
                    airport: {
                      __typename?: 'FdrAirport';
                      iata: string;
                      name: string;
                      codes?: Array<string> | null;
                      isGroup: boolean;
                      id: string;
                    };
                  }
                | {
                    __typename?: 'FdrSearchControlsGeoDestination';
                    geo:
                      | {
                          __typename?: 'FdrDestination';
                          name: string;
                          id: string;
                          region?: {
                            __typename?: 'FdrRegion';
                            name: string;
                            id: string;
                            country: {
                              __typename?: 'FdrCountry';
                              name: string;
                              id: string;
                              countryGroup?: {
                                __typename?: 'FdrCountryGroup';
                                name: string;
                                id: string;
                                continent: {
                                  __typename?: 'FdrContinent';
                                  name: string;
                                  id: string;
                                };
                              } | null;
                              continent: {
                                __typename?: 'FdrContinent';
                                name: string;
                                id: string;
                              };
                            };
                          } | null;
                          country: {
                            __typename?: 'FdrCountry';
                            name: string;
                            id: string;
                            countryGroup?: {
                              __typename?: 'FdrCountryGroup';
                              name: string;
                              id: string;
                              continent: {
                                __typename?: 'FdrContinent';
                                name: string;
                                id: string;
                              };
                            } | null;
                            continent: {
                              __typename?: 'FdrContinent';
                              name: string;
                              id: string;
                            };
                          };
                        }
                      | {
                          __typename?: 'FdrCountry';
                          name: string;
                          id: string;
                          countryGroup?: {
                            __typename?: 'FdrCountryGroup';
                            name: string;
                            id: string;
                            continent: {
                              __typename?: 'FdrContinent';
                              name: string;
                              id: string;
                            };
                          } | null;
                          continent: {
                            __typename?: 'FdrContinent';
                            name: string;
                            id: string;
                          };
                        }
                      | {
                          __typename?: 'FdrContinent';
                          name: string;
                          id: string;
                        }
                      | {
                          __typename?: 'FdrCountryGroup';
                          name: string;
                          id: string;
                          continent: {
                            __typename?: 'FdrContinent';
                            name: string;
                            id: string;
                          };
                        }
                      | {
                          __typename?: 'FdrRegion';
                          name: string;
                          id: string;
                          country: {
                            __typename?: 'FdrCountry';
                            name: string;
                            id: string;
                            countryGroup?: {
                              __typename?: 'FdrCountryGroup';
                              name: string;
                              id: string;
                              continent: {
                                __typename?: 'FdrContinent';
                                name: string;
                                id: string;
                              };
                            } | null;
                            continent: {
                              __typename?: 'FdrContinent';
                              name: string;
                              id: string;
                            };
                          };
                        }
                      | {
                          __typename?: 'FdrResort';
                          name: string;
                          id: string;
                          destination: {
                            __typename?: 'FdrDestination';
                            name: string;
                            id: string;
                            region?: {
                              __typename?: 'FdrRegion';
                              name: string;
                              id: string;
                              country: {
                                __typename?: 'FdrCountry';
                                name: string;
                                id: string;
                                countryGroup?: {
                                  __typename?: 'FdrCountryGroup';
                                  name: string;
                                  id: string;
                                  continent: {
                                    __typename?: 'FdrContinent';
                                    name: string;
                                    id: string;
                                  };
                                } | null;
                                continent: {
                                  __typename?: 'FdrContinent';
                                  name: string;
                                  id: string;
                                };
                              };
                            } | null;
                            country: {
                              __typename?: 'FdrCountry';
                              name: string;
                              id: string;
                              countryGroup?: {
                                __typename?: 'FdrCountryGroup';
                                name: string;
                                id: string;
                                continent: {
                                  __typename?: 'FdrContinent';
                                  name: string;
                                  id: string;
                                };
                              } | null;
                              continent: {
                                __typename?: 'FdrContinent';
                                name: string;
                                id: string;
                              };
                            };
                          };
                        };
                  }
                | {
                    __typename?: 'FdrSearchControlsProductDestination';
                    product:
                      | { __typename?: 'FdrCruise'; name: string; id: string }
                      | {
                          __typename?: 'FdrHotel';
                          name: string;
                          mythosCode?: string | null;
                          id: string;
                          resort: {
                            __typename?: 'FdrResort';
                            name: string;
                            id: string;
                            destination: {
                              __typename?: 'FdrDestination';
                              name: string;
                              id: string;
                              region?: {
                                __typename?: 'FdrRegion';
                                name: string;
                                id: string;
                                country: {
                                  __typename?: 'FdrCountry';
                                  name: string;
                                  id: string;
                                  countryGroup?: {
                                    __typename?: 'FdrCountryGroup';
                                    name: string;
                                    id: string;
                                    continent: {
                                      __typename?: 'FdrContinent';
                                      name: string;
                                      id: string;
                                    };
                                  } | null;
                                  continent: {
                                    __typename?: 'FdrContinent';
                                    name: string;
                                    id: string;
                                  };
                                };
                              } | null;
                              country: {
                                __typename?: 'FdrCountry';
                                name: string;
                                id: string;
                                countryGroup?: {
                                  __typename?: 'FdrCountryGroup';
                                  name: string;
                                  id: string;
                                  continent: {
                                    __typename?: 'FdrContinent';
                                    name: string;
                                    id: string;
                                  };
                                } | null;
                                continent: {
                                  __typename?: 'FdrContinent';
                                  name: string;
                                  id: string;
                                };
                              };
                            };
                          };
                        }
                      | {
                          __typename?: 'FdrRoundTrip';
                          name: string;
                          id: string;
                        }
                      | {
                          __typename?: 'FdrAdventureTravel';
                          name: string;
                          id: string;
                        };
                  }
                | {
                    __typename?: 'FdrSearchControlsThemeDestination';
                    theme: {
                      __typename?: 'FdrTheme';
                      name: string;
                      id: string;
                    };
                  }
              > | null;
              radiusSearch?: {
                __typename?: 'FdrSearchControlsRadiusSearch';
                label: string;
                radius: number;
                center: {
                  __typename?: 'FdrCoordinates';
                  latitude: number;
                  longitude: number;
                };
              } | null;
            } | null;
          };
        };
      }
    | { __typename?: 'FdrAdventureTravel'; id: string }
    | null;
};

export const FdrPdpSearchControlsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrPdpSearchControls' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchQuery' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'travelType' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'FdrProductTravelType' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrProduct' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'publicId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrHotel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'searchControls' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'input' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'searchQuery' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'searchQuery',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'travelType' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'travelType' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fdrSearchControlsResponse',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrRoundTrip' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'searchControls' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'input' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'searchQuery' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'searchQuery',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'travelType' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'travelType' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fdrSearchControlsResponse',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrCruise' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'searchControls' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'input' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'searchQuery' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'searchQuery',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'travelType' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'travelType' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fdrSearchControlsResponse',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrSearchControlsResponseFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrPdpSearchControlsQuery__
 *
 * To run a query within a React component, call `useFdrPdpSearchControlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrPdpSearchControlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrPdpSearchControlsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      searchQuery: // value for 'searchQuery'
 *      travelType: // value for 'travelType'
 *   },
 * });
 */
export function useFdrPdpSearchControlsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FdrPdpSearchControlsQuery,
    FdrPdpSearchControlsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrPdpSearchControlsQuery,
    FdrPdpSearchControlsQueryVariables
  >(FdrPdpSearchControlsDocument, options);
}
export function useFdrPdpSearchControlsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrPdpSearchControlsQuery,
    FdrPdpSearchControlsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrPdpSearchControlsQuery,
    FdrPdpSearchControlsQueryVariables
  >(FdrPdpSearchControlsDocument, options);
}
export type FdrPdpSearchControlsQueryHookResult = ReturnType<
  typeof useFdrPdpSearchControlsQuery
>;
export type FdrPdpSearchControlsLazyQueryHookResult = ReturnType<
  typeof useFdrPdpSearchControlsLazyQuery
>;
export type FdrPdpSearchControlsQueryResult = Apollo.QueryResult<
  FdrPdpSearchControlsQuery,
  FdrPdpSearchControlsQueryVariables
>;
